.ProjectsTitle {
    padding-bottom: 7%;
    margin-top: 8%;
    font-size: 5em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 0;
    
}



.ProjectsContent {
    min-height: 75vh;
}

.Gallery {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}


.ProjectImage {
    width: 100%;

}

.ProjectTile {
    position: relative;    
    display: inline-block;
    width: 25%;
}

#MaskProjectTile {
    float: right;
    display: inline-block;
}

#BooProjectTile {
    float: left;
    display: inline-block;
}

.ProjectTitle {
    position: absolute;
    top: 10px;
    background-color:#0B0C10;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.ProjectTitle p {
    color: #66FCF1;
    font-size: 30px;
    text-align: center;
}

.Button {
    position: absolute;
    bottom: 10px;
    background-color:#0B0C10;
    width: 85%;
    margin-left: 7.5%;
    margin-right: 7.5%;
    height: 10%;
    padding-top: 10px;

}

.Button a {
    color: #66FCF1;
    font-size: 20px;
    text-decoration: none;
    
}

.Button a:hover {

   color: white;
   cursor: pointer;

}

@media only screen and (max-width: 1025px) {

    .ProjectsTitle {
        margin-top: 10%;
        font-size: 5em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 10%;
        padding: 0;
    }

    
    .ProjectImage {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    
    }
    
    .ProjectTile {
        position: relative;    
        width: 30%;

    }
    
    .ProjectTitle {
        position: absolute;
        top: 0;
        background-color:#0B0C10;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    
    .ProjectTitle p {
        color: #66FCF1;
        font-size: 30px;
        text-align: center;
    }
    
    .Button {
        position: absolute;
        bottom: 0px;
        background-color:#0B0C10;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 10px;
    
    }
    
    .Button a {
        color: #66FCF1;
        font-size: 25px;
        text-align: center;
    }
    

}

@media only screen and (max-width: 1024px) {

    .ProjectsTitle {
        margin-top: 15%;
        font-size: 5em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 5%;
        padding: 0;
    }

    

    .ProjectTile {
        position: relative;    
        width: 50%;

    }
    
    .ProjectTitle {
        position: absolute;
        top: 0;
        background-color:#0B0C10;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    

}



@media only screen and (max-width: 767.98px) {

    .ProjectsTitle {
        margin-top: 20%;
        font-size: 3em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 0;
    }

    
    .ProjectImage {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    
    }
    
    .ProjectTile {
        position: relative;    
        width: 70%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;

    }
    
    .ProjectTitle {
        position: absolute;
        top: 0px;
        background-color:#0B0C10;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }
    
    .ProjectTitle p {
        color: #66FCF1;
        font-size: 20px;
        text-align: center;
    }
    
    .Button {
        position: absolute;
        bottom: 10px;
        background-color:#0B0C10;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    
    }
    
    .Button a {
        color: #66FCF1;
        font-size: 20px;
        text-align: center;
    }
}


