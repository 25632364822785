.MasqueradeProject h1 {
    padding-bottom: 7%;
    margin-top: 8%;
    font-size: 3em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 2%;
    padding-bottom: 0;
}

.MasqueradeProject h2 {
    padding-bottom: 7%;
    font-size: 2em;
    text-transform: uppercase;
    color: #66FCF1;
    margin-bottom: 0;
    padding-bottom: 0;

}

.MasqueradeProject p {
    font-size: 1.3em;
}

.MasqueradeProject {
    width: 90%;
    margin-left: 5%;
}

@media only screen and (max-width: 1025px) {

    .MasqueradeProject {

        margin-bottom: 20%;
        margin-top: 20%;
    }


}

@media only screen and (max-width: 767.98px) {

    .video iframe {
        width: 90%;
    }

    .MasqueradeProject {

        margin-bottom: 20%;
        margin-top: 20%;
    }

}