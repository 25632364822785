    
nav {
    float: right;
}


header {
    top: 0;
    left: 0;
    height: 10%;
    width: 100%;
    background-color:#0B0C10;
    padding: 0 100px;
    box-sizing:  border-box;
    position: fixed;
}

header nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
}

header nav ul li a {
    padding: 0 20px;
    text-decoration: none;
    color: #ffffff;
    height: 90px;
    line-height: 90px;
    display: block;
}

header nav ul li a:hover {
    color: #66FCF1;
}




@media only screen and (max-width: 1024px) {
    header nav ul li a {
        font-size: 20px;
    }

    
    header .logo {
        display: block;
        padding: 0;
        width: 40vw;
        height: auto;
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    header {
        height: 10%;
    }

    header nav{
        display: none;
       
    }
}



@media only screen and (max-width: 767.98px) {
    header nav{
        display: none;
       
    }

    header {
        height: 8%;
    }

    header .logo {
        display: block;
        padding: 0;
       
        width: 40vw;
        height: auto;
        float: none;
    }
}


@media (max-width: 360px) {
    header {
        height: 10%;
        padding-top: 4px;
    }

 
}

