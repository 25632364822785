.HomeContent{
    min-height: 71vh;
}

.Hello {
    font-size: 13em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 0;
    letter-spacing: 0.02em;
    margin-top: 10%;
}

.HomepageParagraph {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5em;
    text-align: justify;
}

.ShowreelButton {
    border: #66FCF1 solid 3px;
    width: 30%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-top: 3%;
}

.ShowreelButton a {
    text-decoration: none;
    font-size: 1.5em;
    text-transform: uppercase;
}

.ShowreelButton:hover{
    border: white solid 3px;
    cursor: pointer;
}

.ShowreelButton a:hover {
    color: #66FCF1;
}

@media only screen and (max-width: 1025px) {

    .Hello {
        margin-top: 15%;
    }
    
    .HomepageParagraph {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 40px;
    }

    .ShowreelButton {
        border: #66FCF1 solid 3px;
        width: 68%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        margin-top: 7%;
    }

    
    .ShowreelButton a {
        text-decoration: none;
        font-size:  3em;
        text-transform: uppercase;
    }

  


}


@media only screen and (max-width: 1023px) {

    .HomepageParagraph {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 40px;
    }

    .ShowreelButton {
        border: #66FCF1 solid 3px;
        width: 68%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        margin-top: 7%;
    }

    
    .ShowreelButton a {
        text-decoration: none;
        font-size:  3em;
        text-transform: uppercase;
    }

    
.Hello {
    margin-top: 10%;
}


  



}

@media only screen and (max-width: 1000px) {
    .Hello {
        font-size: 12em;
        margin-top: 15%;
    }

    .HomepageParagraph {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 30px;
    }

    .ShowreelButton {
        border: #66FCF1 solid 3px;
        width: 64%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        margin-top: 7%;
    }

    .ShowreelButton a {
        text-decoration: none;
        font-size:  1.8em;
        text-transform: uppercase;
    }

  

}


@media only screen and (max-width: 767.98px) {
    .Hello {
        font-size: 7em;
        margin-top: 15%;
    }

    .HomepageParagraph {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.3em;
    }

    .ShowreelButton {
        border: #66FCF1 solid 3px;
        width: 75%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        margin-top: 7%;
    }

    .ShowreelButton a {
        text-decoration: none;
        font-size:  1.6em;
        text-transform: uppercase;
        margin-bottom: 10%;
    }


    
}

@media (max-width: 375px) {
    .Hello {
        font-size: 6em;
        margin-top: 17%;
        margin-bottom: 0;
    }

    .HomepageParagraph {
        margin-top: 0;
    }




    
} 

@media (max-width: 360px) {
    .HomepageParagraph {
        font-size: 1.1em;
        margin-top: 0;
    }

    .Hello {
        font-size: 5em;
        margin-top: 17%;
        margin-bottom: 0;
    }



    
} 

