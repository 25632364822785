.ShowcaseTitle {
    padding-bottom: 7%;
    margin-top: 8%;
    font-size: 5em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 0;
}

.ShowcaseContent {
    min-height: 75vh;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



@media only screen and (max-width: 1025px) {

    .ShowcaseTitle {
        margin-top: 20%;
        font-size: 5em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 0;
    }

}

.Carousel {

    margin-left: 0%;
    margin-right: auto;

}


@media only screen and (max-width: 767.98px) {

    .ShowcaseTitle {
        margin-top: 20%;
        font-size: 3em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 0;
    }

}