.Title {
    margin-top: 8%;
    font-size: 5em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 3%;
}

.ContactContent {
    text-align: center;
    min-height: 75vh;
}

.ContactParagraph {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 100px;
    margin-top:0;
}

label {
    font-size: 1.5em;
}

input {
    width: 30%;
    font-size: 1em;
    margin-left: 20px;
    margin-bottom: 20px;
}

textarea{
    width: 30%;
    font-size: 1em;
    margin-left: 33px;
}



.SubmitButton {
    border: #66FCF1 solid 3px;
    width: 40%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-top: 5%;
    text-decoration: none;
    font-size: 1.5em;
    text-transform: uppercase;
    background-color: #191c24;
    color: white;
}



.SubmitButton:hover{
    border: white solid 3px;
    cursor: pointer;
    color: #66FCF1;
}


@media only screen and (max-width: 1025px) {

.Title {
    margin-top: 20%;
    font-size: 5em;
    text-transform: uppercase;
    text-align: center;
    color: #66FCF1;
    margin-bottom: 0;
}

.ContactParagraph {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
}

label {
    font-size: 30px;
}

input {
    width: 50%;
    font-size: 1em;
    margin-left: 20px;
    margin-bottom: 20px;
}

textarea{
    width: 50%;
    font-size: 1em;
    margin-left: 33px;
}


.SubmitButton {
    border: #66FCF1 solid 3px;
    width: 64%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-top: 5%;
    text-decoration: none;
    font-size: 1.5em;
    text-transform: uppercase;
    background-color: #191c24;
    color: white;
}


    

}

@media only screen and (max-width: 1000px) {
    .SubmitButton {
        border: #66FCF1 solid 3px;
        width: 75%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        margin-top: 5%;
        text-decoration: none;
        font-size: 1.5em;
        text-transform: uppercase;
        background-color: #191c24;
        color: white;
    }
    

}

@media only screen and (max-width: 767.98px) {

    .Title {
        margin-top: 20%;
        font-size: 3em;
        text-transform: uppercase;
        text-align: center;
        color: #66FCF1;
        margin-bottom: 0;
    }
    
    .ContactParagraph {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        margin-bottom: 10px;
    }
    
    label {
        font-size: 20px;
    }
    
    input {
        width: 50%;
        font-size: 1em;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    
    textarea{
        width: 50%;
        font-size: 1em;
        margin-left: 33px;
    }
    
    
    .SubmitButton {
        border: #66FCF1 solid 3px;
        width: 80%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        margin-top: 5%;
        text-decoration: none;
        font-size: 20px;
        text-transform: uppercase;
        background-color: #191c24;
        color: white;
    }
    

}