p, a {
    color: white;
}

footer {
    position: relative;
    width: 100%;
    background-color:#0B0C10;
}

.footer p {
 vertical-align: middle;
}

.bottomnav {
    display:none;
}



.rightfooter {
    display: inline-block;
    float: right; 
    text-align: right;
    width: 40%;
    padding-right: 20px;
}


@media only screen and (max-width: 1025px) {
    .bottomnav {
        display: block;
        list-style: none;
        bottom: 0;
        width: 100%;
        position: fixed;
        text-align: center;
        background-color:#0B0C10;
    }

    .bottomnav ul li {
        display: inline-block;
        width: 25%;
        text-align: center;

    }

    .bottomnav ul {
        padding: 0;
    }

    .bottomnav ul li a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 40px;
    }

    .footer {
        display: none;
    }
}



@media only screen and (max-width: 1023px) {
    .bottomnav {
        display: block;
        list-style: none;
        bottom: 0;
        width: 100%;
        position: fixed;
        text-align: center;
        background-color:#0B0C10;
    }

    .bottomnav ul li {
        display: inline-block;
        width: 25%;
        text-align: center;

    }

    .bottomnav ul {
        padding: 0;
    }

    .bottomnav ul li a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
    }

    .footer {
        display: none;
    }
}